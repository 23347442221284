<template>
    <div>
        <v-container>


            <div v-if="!isLogged" style="width=100%" class="mt-12 d-flex flex-column align-center text-center">
                <v-card color="" width="600">
                    <h1 class="mt-6 mb-12">
                        {{ $t('passwordforgot.title') }}
                    </h1>

                    <form class="px-6">
                        <v-text-field v-model="email" :color="negative_color_class"
                            :label="$t('passwordforgot.field.email')" outlined :rules="emailRules" />
                    </form>
                    <p class="text-center">{{ $t('passwordforgot.message.instruction') }} </p>



                    <div class="px-6 mt-12 mb-6">
                        <v-btn block color="secondary" @click="sendPasswordReset()" :disabled="pwd_reset_disable">
                            <span class="primary--text">
                                {{ $t('passwordforgot.btn.reset') }}
                            </span>
                        </v-btn>
                        <br>
                        <v-btn color="primary" text :to="{ name: 'Admin' }">
                            <span class="secondary--text">
                                {{ $t('passwordforgot.btn.back') }}
                            </span>
                        </v-btn>
                    </div>
                </v-card>
            </div>


            <!-- <v-row>
                <v-col cols=12>
                    <v-card :color="positive_color_class" width="400">
                        <v-card-text>
                            <h1 class="mb-12">{{ $t('passwordforgot.title') }}</h1>
                            <form>
                                <v-text-field v-model="email" :color="negative_color_class"
                                    :label="$t('passwordforgot.field.email')" outlined :rules="emailRules" />
                            </form>

                            <p class="text-center">{{ $t('passwordforgot.message.instruction') }} </p>

                        </v-card-text>
                        <v-card-actions>
                            <v-btn block color="secondary" @click="sendPasswordReset()" :disabled="pwd_reset_disable">
                                <span class="primary--text">
                                    {{ $t('passwordforgot.btn.reset') }}
                                </span>
                            </v-btn>
                        </v-card-actions>
                        <v-row no-gutters>
                            <v-col class="text-right pr-2 my-2" cols=12>
                                <v-btn color="primary" text :to="{ name: 'Admin' }">
                                    <span class="secondary--text">
                                        {{ $t('passwordforgot.btn.back') }}
                                    </span>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row> -->
        </v-container>
    </div>
</template>

<script>

export default {
    props: {},
    components: {},
    data: () => ({
        email: "",
        pwd_reset_disable: false,
        emailRules: [],
        emailError: true,
    }),
    methods: {
        sendPasswordReset() {
            this.pwd_reset_disable = true

            if (this.email == "" || this.emailError) {
                alert(this.$t('passwordforgot.error.wrongEmail'))
                this.pwd_reset_disable = false
                return
            }

            this.$store.dispatch("auth/act_pwd_reset", this.email).then((exit) => {
                if (exit) {
                    console.log(String(exit));
                    if (String(exit).includes('auth/user-not-found')) {
                        alert(this.$t('passwordforgot.error.missingAccount'))
                        this.pwd_reset_disable = false
                    }
                } else {
                    alert(this.$t('passwordforgot.message.linkSent'))
                    this.$router.push({ name: 'Admin' })

                }
            })

            // if (this.pwd1 == "") {
            //     alert(this.$t('passwordforgot.error.password_empty'))
            // }
            // this.$store.dispatch("auth/act_pwd_change", this.pwd1).then((exit) => {
            //     console.log(exit);
            //     if (exit == false) {
            //         alert(this.$t('passwordforgot.alert.passwordforgotd'))
            //         this.$router.push({ name: 'Admin' })
            //     }
            //     this.pwd_reset_disable = false

            // }).catch(error => {
            //     alert(error)
            // })
        }
    },
    mounted() { },
    created() { },
    computed: {},
    watch: {
        email: function (mail) {
            if (mail !== '') {
                // this.emailRules = [v => (v.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) || this.$t('contactus.error.invalidemail')]
                this.emailRules = [v => {
                    if (v.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                        this.emailError = false
                        return null
                    } else {
                        this.emailError = true
                        return this.$t('passwordforgot.error.wrongEmail')
                    }
                }
                ]

            } else if (mail === '') {
                this.emailRules = []
            }
        },

    }
}
</script>

<style>

</style>